import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "./Footer.scss";

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="app__container">
          <div className="footer__inner">
            <div>
              <h3 className="footer__heading">Contact Us</h3>
              <ul className="footer__contact-list">
                <li>
                  <strong>Phone</strong>
                  <br />
                  <a href="tel:0416853992">041 6853162</a>
                </li>
                <li>
                  <strong>Fax</strong>
                  <br />
                  <a href="tel:0416853992">041 6853992</a>
                </li>
                <li>
                  <strong>Get a quote</strong>
                  <br />
                  <a href="mailto:info@mawhately.ie">info@mawhately.ie</a>
                </li>
                <li>
                  <strong>Address</strong> <br />
                  <a
                    href="https://www.google.com/maps/place/M+A+Whately+%26+Company+Accountants/@53.854911,-6.5436947,17z/data=!3m1!4b1!4m5!3m4!1s0x48674a5af8604a8d:0xf80d03e9b1bbeacd!8m2!3d53.8549079!4d-6.541506"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Jervis St, Ardee, Co. Louth.
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <a
                className="footer__linkedin"
                href="https://www.linkedin.com/company/m-a-whately-&-co/about/"
              >
                <img src="../img/logo_linkedin.svg" alt="Company logo" />
                <span>Connect with us on linkedin</span>
              </a>
              <NavLink to={`/`} exact={true} className="footer__logo-container">
                <img src="../img/logo_vector.svg" alt="Company logo" />
              </NavLink>
              <p className="footer__meta-text">
                <NavLink to={`/privacy-policy`} exact={true}>
                  Privacy Policy
                </NavLink>
                <br />
                <a
                  className="footer__author"
                  href="https://www.mcclean.ie"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Development & UX by McClean Creative
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
