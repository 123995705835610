import React, { Component, Fragment } from "react";
import { FormValidation } from "calidation";

import "./Contact.scss";

class ContactForm extends Component {
  onSubmit = ({ fields, errors, isValid }) => {
    if (isValid) {
      document.getElementById("blark").submit(); // why blark? why not!
    }
  };

  inputChanged() {
    return;
  }

  render() {
    const config = {
      name: {
        isRequired: "Name is required!"
      },
      email: {
        isRequired: "Email field is required!",
        isEmail: "Valid emails only, please!"
      },
      message: {
        isRequired: "Message field is required!"
      }
    };

    return (
      <div className="contact">
        <div>
          <p>
            <a
              href="https://www.google.com/maps/place/M+A+Whately+%26+Company+Accountants/@53.854911,-6.5436947,17z/data=!3m1!4b1!4m5!3m4!1s0x48674a5af8604a8d:0xf80d03e9b1bbeacd!8m2!3d53.8549079!4d-6.541506"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>M. A. Whately & Co.</strong>
              <br />
              Jervis St
              <br />
              Ardee
              <br />
              Co. Louth <br />
            </a>
          </p>
          <p>
            Tel : <a href="tel:+353416853162">041 6853162</a> <br />
            Fax : <a href="tel:+353416853992">041 6853992</a> <br />
          </p>
          <p>
            Email:&nbsp;
            <a href="mailto:info@mawhately.ie?subject=Mail from Our Site">
              info@mawhately.ie
            </a>
          </p>
        </div>
        <FormValidation
          onSubmit={this.onSubmit}
          config={config}
          action="https://formspree.io/info@mawhately.ie"
          method="POST"
          id="blark"
        >
          {({ fields, errors, submitted }) => (
            <Fragment>
              <label htmlFor="name">Name</label>
              <input
                name="name"
                type="text"
                value={fields.name}
                onChange={this.inputChanged}
              />
              {submitted && errors.name && (
                <p className="contact__error-message">{errors.name}</p>
              )}
              <label htmlFor="email">Email</label>
              <input
                name="email"
                type="email"
                value={fields.email}
                onChange={this.inputChanged}
              />
              {submitted && errors.email && (
                <p className="contact__error-message">{errors.email}</p>
              )}
              <label htmlFor="message">Message</label>
              <textarea
                name="message"
                value={fields.message}
                onChange={this.inputChanged}
              />
              {submitted && errors.message && (
                <p className="contact__error-message">{errors.message}</p>
              )}
              <input
                type="hidden"
                name="_next"
                value={`//${window.location.hostname}/contact/thankyou`}
              />
              <input type="submit" value="Send" />
            </Fragment>
          )}
        </FormValidation>
      </div>
    );
  }
}

export default ContactForm;
