import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ContentfulClient, ContentfulProvider } from "react-contentful";
import TagManager from "react-gtm-module";

import ScrollToTop from "../../utils/ScrollToTop";

import "./App.scss";
import Header from "../Header/Component";
import Footer from "../Footer/Component";
import Routes from "./Routes";

const tagManagerArgs = {
  gtmId: "GTM-5X22DDQ",
};

const contentfulClient = new ContentfulClient({
  accessToken: "Y2GuqD-v2m84l5LX2v1c5Ci8_NI7mLY4gyO3LhUhogY",
  space: "6s68t0h3z44x",
});

TagManager.initialize(tagManagerArgs);

class App extends Component {
  render() {
    return (
      <ContentfulProvider client={contentfulClient}>
        <Router>
          <ScrollToTop>
            <div className="app">
              <Header />
              <main>
                <Routes />
              </main>
              <Footer />
            </div>
          </ScrollToTop>
        </Router>
      </ContentfulProvider>
    );
  }
}

export default App;
