import React, { Component } from "react";
import Slider from "react-slick";
import { NavLink } from "react-router-dom";

import "./Carousel.scss";

class Carousel extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true
    };
    return (
      <Slider {...settings} className="home-carousel">
        <div className="home-carousel__slide-container">
          <img
            alt="accounting"
            src="img/custom_resized_c48d9052-4ea8-4980-a2a8-68b80805a010.jpg"
          />
          <div className="home-carousel__inner">
            <div className="app__container home-carousel__flex-vertical-align">
              <NavLink to={`/services/accounting`} exact={true}>
                <h3>Accounting</h3>
                <p>
                  Let us provide you with more time to concentrate on running
                  your business, and the peace of mind that your accounts and
                  returns are in order.
                </p>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="home-carousel__slide-container">
          <img
            alt="taxation"
            src="img/custom_resized_6ab6c769-55b8-49fb-acba-df6949768c4a.jpg"
          />
          <div className="home-carousel__inner">
            <div className="app__container home-carousel__flex-vertical-align">
              <NavLink to={`/services/taxation`} exact={true}>
                <h3>Taxation</h3>
                <p>
                  We provide a comprehensive range of services from the
                  completion of tax returns under the self assessment system to
                  complex consultancy assignments and strategic tax planning.
                </p>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="home-carousel__slide-container">
          <img
            alt="image1"
            src="img/custom_resized_d23f2131-46db-457c-b675-65038ec36cc3.jpg"
          />
          <div className="home-carousel__inner">
            <div className="app__container home-carousel__flex-vertical-align">
              <NavLink to={`/services/payroll`} exact={true}>
                <h3>Payroll</h3>
                <p>
                  Many organisations now use our payroll service. We calculate
                  the net pay and PAYE/PRSI deductions. The numbers of employees
                  in these firms can be as few as one and as many as 500.
                </p>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="home-carousel__slide-container">
          <img
            alt="image1"
            src="img/custom_resized_3174c2bf-116d-4ac2-bc7e-027ebf3af365.jpg"
          />
          <div className="home-carousel__inner">
            <div className="app__container home-carousel__flex-vertical-align">
              <NavLink to={`/services/audit`} exact={true}>
                <h3>Auditing</h3>
                <p>
                  We can perform your auditing and financial analysis with a
                  minimum of fuss and maximum efficiency and accuracy.
                </p>
              </NavLink>
            </div>
          </div>
        </div>
      </Slider>
    );
  }
}

export default Carousel;
