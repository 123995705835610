import React, { Component } from "react";
import { Helmet } from "react-helmet";
import "./Impact.scss";

class CharityFrame extends Component {
  iframe() {
    return {
      __html: this.props.iframe
    };
  }

  render() {
    return <div dangerouslySetInnerHTML={this.iframe()} />;
  }
}

const widget1 = `<iframe height="396" width="300" src="https://b1g1.com/frontgivingwidget/mywidget/16abf5c8ea1116bd4ab3cb0040bd1dcc" style="border: none;position: absolute; top: -9999em; visibility: hidden;" onload="this.style.position=&apos;static&apos;; this.style.visibility=&apos;visible&apos;;"></iframe>`;

const widget2 = `<div class="sdg-plan b1g1-sdg b1g1-sdg-widget" style="max-width: 180px;text-align: center;position: relative;float: left;margin:20px;" data-key="5da3ce48124c2f9e641c983d72d96dcc" data-value="4" data-host="https://b1g1.com" data-server="https://api.b1g1.com"><div class="img-sec" style="position: relative;"><img class="sdg-img" src="https://api.b1g1.com/uploads/sdg_widget/big-icon/E_SDG_Icons-4.jpg" alt="B1G1 SDG" style="width: 100%;display:block;"><div class="img-hover-text" style="background:#c32033;position: absolute;left: 0px;right: 0px;top: 0px;bottom: 0px;display: none;cursor:pointer;"><p class="sdg-text-4" style="text-align: left;color: #fff;font-size: 13px;padding: 0px 15px;font-family:Avenir;"></p></div></div><section style="padding-top: 7px;padding-bottom: 7px;float: left;width: 100%;background-color:white;"><h3 class="timer count-title count-number impact-4" style="font-size: 22px;font-weight: bold;color: #000000;margin: 3px;font-family:Avenir;"></h3><h5 class="sdg-impact-text-4" style="font-size: 14px;font-weight: 400;color: #000000;margin: 0px;font-family:Avenir;"></h5></section></div><script src="https://b1g1.com/static/widgets/sdg_widget.js"></script>`;

const widget3 = `<div class="sdg-plan b1g1-sdg b1g1-sdg-widget" style="max-width: 180px;text-align: center;position: relative;float: left;margin:20px;" data-key="5da3ce48124c2f9e641c983d72d96dcc" data-value="1" data-host="https://b1g1.com" data-server="https://api.b1g1.com"><div class="img-sec" style="position: relative;"><img class="sdg-img" src="https://api.b1g1.com/uploads/sdg_widget/big-icon/E_SDG_Icons-1.jpg" alt="B1G1 SDG" style="width: 100%;display:block;"><div class="img-hover-text" style="background:#EB1C2D;position: absolute;left: 0px;right: 0px;top: 0px;bottom: 0px;display: none;cursor:pointer;"><p class="sdg-text-1" style="text-align: left;color: #fff;font-size: 13px;padding: 0px 15px;font-family:Avenir;"></p></div></div><section style="padding-top: 7px;padding-bottom: 7px;float: left;width: 100%;background-color:white;"><h3 class="timer count-title count-number impact-1" style="font-size: 22px;font-weight: bold;color: #000000;margin: 3px;font-family:Avenir;"></h3><h5 class="sdg-impact-text-1" style="font-size: 14px;font-weight: 400;color: #000000;margin: 0px;font-family:Avenir;"></h5></section></div><script src="https://b1g1.com/static/widgets/sdg_widget.js"></script>`;

const widget4 = `<div class="sdg-plan b1g1-sdg b1g1-sdg-widget" style="max-width: 180px;text-align: center;position: relative;float: left;margin:20px;" data-key="5da3ce48124c2f9e641c983d72d96dcc" data-value="10" data-host="https://b1g1.com" data-server="https://api.b1g1.com"><div class="img-sec" style="position: relative;"><img class="sdg-img" src="https://api.b1g1.com/uploads/sdg_widget/big-icon/E_SDG_Icons-10.jpg" alt="B1G1 SDG" style="width: 100%;display:block;"><div class="img-hover-text" style="background:#e01483;position: absolute;left: 0px;right: 0px;top: 0px;bottom: 0px;display: none;cursor:pointer;"><p class="sdg-text-10" style="text-align: left;color: #fff;font-size: 13px;padding: 0px 15px;font-family:Avenir;"></p></div></div><section style="padding-top: 7px;padding-bottom: 7px;float: left;width: 100%;background-color:white;"><h3 class="timer count-title count-number impact-10" style="font-size: 22px;font-weight: bold;color: #000000;margin: 3px;font-family:Avenir;"></h3><h5 class="sdg-impact-text-10" style="font-size: 14px;font-weight: 400;color: #000000;margin: 0px;font-family:Avenir;"></h5></section></div><script src="https://b1g1.com/static/widgets/sdg_widget.js"></script>`;

const widget5 = `<iframe style="max-width:100%;border: none;position: absolute; top: -9999em; visibility: hidden;" frameborder="0" height="300" width="800" src="https://businessesforgood.com/embed.php?id=11731&id2=Impact&height=300" onload="this.style.position=&apos;static&apos;; this.style.visibility=&apos;visible&apos;;"></iframe>`;

class Impact extends Component {
  render() {
    return (
      <div className="app__content-wrapper">
        <Helmet>
          <title>Whately Accountants | Impact</title>
        </Helmet>
        <h1 className="alt-font">Impact</h1>
        <div className="impact__divider">
          <div className="impact__blurb">
            <h2>
              At M.A. Whately, we care deeply for our clients and the broader
              community
            </h2>
            <p>
              We also believe that every business has the responsibility and
              power to change lives by simple acts of kindness. As such, every
              time you do business with us, something amazing happens.
            </p>
            <p>
              Our partnership with Buy 1 Give 1 (B1G1) enables us to make high
              impact donations to worthy causes, on behalf of our clients. By
              doing business with us, you are helping us impact the lives of
              thousands of people in need.
            </p>
          </div>
          <div>
            <CharityFrame iframe={widget1} />
          </div>
          <div>
            <CharityFrame iframe={widget2} />
            <CharityFrame iframe={widget3} />
            <CharityFrame iframe={widget4} />
          </div>
          <div>
            <CharityFrame iframe={widget5} />
          </div>
        </div>
      </div>
    );
  }
}

export default Impact;
