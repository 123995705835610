import React from "react";
import { Query } from "react-contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Helmet } from "react-helmet";
import ContactForm from "../Contact/ContactForm";
import "./Page.scss";

const Page = (props) => (
  <Query
    contentType="page"
    query={{
      "fields.slug[in]": `${props.slug}`,
    }}
  >
    {({ data, error, fetched, loading }) => {
      if (loading || !fetched) {
        return null;
      }

      if (error) {
        console.error(error);
        return null;
      }

      if (!data || !data.items[0]) {
        return <p>Page does not exist.</p>;
      }

      const { body, metaDescription, metaTitle, title } = data.items[0].fields;
      const leadImage = data.items[0].fields.leadImage;

      const rawRichTextField = body;
      const richTextField = documentToReactComponents(rawRichTextField);

      // Process and pass in the loaded `data` necessary for your page or child components.
      return (
        <div className="app__content-wrapper">
          <Helmet>
            <title>Whately Accountants | {metaTitle}</title>
            <meta name="description" content={metaDescription} />
          </Helmet>
          <div className="page__services">
            <h1 className="alt-font">{title}</h1>
            {leadImage && (
              <img
                className="page__blog-lead-image"
                src={leadImage.fields.file.url}
                alt="article promo"
              />
            )}
            {richTextField}
          </div>

          <div className="page__contact-wrapper">
            <h2 className="alt-font">Contact us now </h2>
            <ContactForm />
          </div>
        </div>
      );
    }}
  </Query>
);

export default Page;
