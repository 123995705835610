import React from "react";
import { Helmet } from "react-helmet";
import "./Homepage.scss";
import Carousel from "./Carousel";
import { Query } from "react-contentful";
const ReactMarkdown = require("react-markdown");

const Homepage = (props) => (
  <Query
    contentType="homePage"
    query={{
      limit: 1,
    }}
  >
    {({ data, error, fetched, loading }) => {
      if (loading || !fetched) {
        return null;
      }

      if (error) {
        console.error(error);
        return null;
      }

      if (!data || !data.items[0]) {
        return <p>Page does not exist.</p>;
      }

      const {
        metaDescription,
        metaTitle,
        title,
        intro,
        smallContentBlock1,
        smallContentBlock2,
        smallContentBlock3,
        testimonial1,
        testimonial2,
        testimonial3,
        outro,
      } = data.items[0].fields;

      // Process and pass in the loaded `data` necessary for your page or child components.
      return (
        <>
          <Helmet>
            <title>Whately Accountants | {metaTitle}</title>
            <meta name="description" content={metaDescription} />
          </Helmet>
          <div className="carousel-container">
            <Carousel />
          </div>
          <div className="app__content-wrapper">
            <h1 className="alt-font text-centered homepage__main-heading">
              {title}
            </h1>
            <h2 className="homepage__blurb">{intro}</h2>
            <section className="homepage__feature-boxes">
              <div className="homepage__feature-boxes__box">
                <div className="homepage__feature-boxes__image-container">
                  <img src="./img/home-feature-01.jpg" alt="Tax" />
                </div>
                <h3 className="homepage__feature-boxes__heading">
                  Professional Services
                </h3>
                <div className="homepage__feature-boxes__blurb">
                  {smallContentBlock1}
                </div>
              </div>
              <div className="homepage__feature-boxes__box">
                <div className="homepage__feature-boxes__image-container">
                  <img src="./img/home-feature-02.jpg" alt="Audit" />
                </div>
                <h3 className="homepage__feature-boxes__heading">
                  Client Engagement
                </h3>
                <div className="homepage__feature-boxes__blurb">
                  {smallContentBlock2}
                </div>
              </div>
              <div className="homepage__feature-boxes__box">
                <div className="homepage__feature-boxes__image-container">
                  <img src="./img/home-feature-03.jpg" alt="Payroll" />
                </div>
                <h3 className="homepage__feature-boxes__heading">
                  Proactive Approach
                </h3>
                <div className="homepage__feature-boxes__blurb">
                  {smallContentBlock3}
                </div>
              </div>
            </section>

            <section>
              <h3 className="alt-font text-centered homepage__sub-heading">
                What our clients say about us
              </h3>
              <div className="homepage__testimonial">
                <blockquote>
                  <ReactMarkdown source={testimonial1} />
                </blockquote>
              </div>
              <div className="homepage__testimonial">
                <blockquote>
                  <ReactMarkdown source={testimonial2} />
                </blockquote>
              </div>
              <div className="homepage__testimonial">
                <blockquote>
                  <ReactMarkdown source={testimonial3} />
                </blockquote>
              </div>
            </section>
            <section className="homepage__filler-text alt-font">
              <ReactMarkdown source={outro} />
            </section>
          </div>
        </>
      );
    }}
  </Query>
);

export default Homepage;
