import React from "react";
import { Switch, Route } from "react-router";

import Homepage from "../Homepage/Component";
import Services from "../Services/Component";
import Resources from "../Resources/Component";
import Links from "../Links/Component";
import Impact from "../Impact/Component";
import Contact from "../Contact/Component";
import PrivacyPolicy from "../PrivacyPolicy/Component";
import NotFound from "../NotFound/Component";
import ThankYouPage from "../Contact/ThankYouPage";
import Page from "../Page/Component";
import BlogPost from "../Blog/Post";
import BlogIndex from "../Blog/Index";

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Homepage} />
    <Route
      exact
      path="/about"
      render={(props) => <Page {...props} slug="about-us" />}
    />
    <Route exact path="/services" component={Services} />
    <Route
      exact
      path="/services/taxation"
      render={(props) => <Page {...props} slug="services/taxation" />}
    />
    <Route
      exact
      path="/services/accounting"
      render={(props) => <Page {...props} slug="services/accounting" />}
    />
    <Route
      exact
      path="/services/audit"
      render={(props) => <Page {...props} slug="services/audit" />}
    />
    <Route
      exact
      path="/services/payroll"
      render={(props) => <Page {...props} slug="services/payroll" />}
    />
    <Route
      exact
      path="/services/secretarial"
      render={(props) => <Page {...props} slug="services/secretarial" />}
    />
    <Route
      exact
      path="/services/wealth-management"
      render={(props) => <Page {...props} slug="services/wealth-management" />}
    />
    <Route
      exact
      path="/services/corporate-finance"
      render={(props) => <Page {...props} slug="services/corporate-finance" />}
    />
    <Route
      exact
      path="/services/business-consultancy"
      render={(props) => (
        <Page {...props} slug="services/business-consultancy" />
      )}
    />
    <Route
      exact
      path="/services/specialist"
      render={(props) => <Page {...props} slug="services/specialist" />}
    />
    <Route exact path="/resources" component={Resources} />
    <Route exact path="/links" component={Links} />
    <Route exact path="/impact" component={Impact} />
    <Route exact path="/contact" component={Contact} />
    <Route exact path="/contact/thankyou" component={ThankYouPage} />
    <Route exact path="/privacy-policy" component={PrivacyPolicy} />
    <Route exact path="/blog" component={BlogIndex} />
    <Route path="/blog/:slug" component={BlogPost} />
    <Route component={NotFound} />
  </Switch>
);

export default Routes;
