import React, { Component } from "react";
import { Helmet } from "react-helmet";

import "./Contact.scss";

class ThankYouPage extends Component {
  render() {
    return (
      <div className="app__content-wrapper">
        <Helmet>
          <title>Whately Accountants | Thank you</title>
        </Helmet>
        <h1 className="alt-font">Thank You</h1>
        <p>Someone will answer your query as soon as possible.</p>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default ThankYouPage;
