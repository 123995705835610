import React, { Component } from "react";
import { Helmet } from "react-helmet";

class Links extends Component {
  render() {
    return (
      <div className="app__content-wrapper">
        <Helmet>
          <title>Whately Accountants | Links</title>
        </Helmet>
        <h1 className="alt-font">Links</h1>
        <ul>
          <li>
            <a
              href="https://www.revenue.ie"
              target="_blank"
              rel="noopener noreferrer"
            >
              The Revenue Commissioners official
            </a>
          </li>
          <li>
            <a
              href="https://www.ros.ie"
              target="_blank"
              rel="noopener noreferrer"
            >
              Revenue On Line Website
            </a>
          </li>
          <li>
            <a
              href="https://www.dbei.gov.ie"
              target="_blank"
              rel="noopener noreferrer"
            >
              Department of Business, Enterprise and Innovation
            </a>
          </li>
          <li>
            <a
              href="https://www.welfare.ie"
              target="_blank"
              rel="noopener noreferrer"
            >
              Department of Social Welfare website
            </a>
          </li>
          <li>
            <a
              href="https://www.finance.gov.ie"
              target="_blank"
              rel="noopener noreferrer"
            >
              Department of Finance website
            </a>
          </li>
          <li>
            <a
              href="https://www.finance.gov.ie"
              target="_blank"
              rel="noopener noreferrer"
            >
              Department of Finance website
            </a>
          </li>
          <li>
            <a
              href="https://www.hrmc.gov.uk"
              target="_blank"
              rel="noopener noreferrer"
            >
              UK Revenue website
            </a>
          </li>
          <li>
            <a
              href="https://www.accaglobal.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Association of Chartered Certified Accountants
            </a>
          </li>
          <li>
            <a
              href="http://www.solas.ie"
              target="_blank"
              rel="noopener noreferrer"
            >
              SOLAS –The Further Education and Training Authority
            </a>
          </li>
          <li>
            <a
              href="https://www.cro.ie"
              target="_blank"
              rel="noopener noreferrer"
            >
              Company Registration Office
            </a>
          </li>
          <li>
            <a
              href="https://www.localenterprise.ie"
              target="_blank"
              rel="noopener noreferrer"
            >
              Local Enterprise Office
            </a>
          </li>
          <li>
            <a
              href="https://www.enterprise-ireland.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              State agency supporting business development website
            </a>
          </li>
          <li>
            <a
              href="https://www.isme.ie/advice"
              target="_blank"
              rel="noopener noreferrer"
            >
              Irish Small and Medium Enterprise Association
            </a>
          </li>
        </ul>
        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default Links;
