import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";

import "./Services.scss";
class Services extends Component {
  render() {
    return (
      <div className="app__content-wrapper">
        <Helmet>
          <title>Whately Accountants | Services</title>
        </Helmet>
        <h1 className="alt-font">Services</h1>
        <ul>
          <li>
            <NavLink to={`/services/taxation`}>Tax</NavLink>
          </li>
          <li>
            <NavLink to={`/services/accounting`}>Accounting</NavLink>
          </li>
          <li>
            <NavLink to={`/services/audit`}>Audit</NavLink>
          </li>
          <li>
            <NavLink to={`/services/payroll`}>Payroll</NavLink>
          </li>
          <li>
            <NavLink to={`/services/secretarial`}>Company Secretarial</NavLink>
          </li>
          <li>
            <NavLink to={`/services/wealth-management`}>
              Managing Your Wealth
            </NavLink>
          </li>
          <li>
            <NavLink to={`/services/corporate-finance`}>
              Corporate Finance
            </NavLink>
          </li>
          <li>
            <NavLink to={`/services/business-consultancy`}>
              Business Consultancy
            </NavLink>
          </li>
          <li>
            <NavLink to={`/services/specialist`}>Specialist services</NavLink>
          </li>
        </ul>

        <h2>Managing Your Wealth</h2>
        <span id="manage" className="services__offset-anchor">
          &nbsp;
        </span>
        <p>
          We can help ensure that your personal wealth is managed as
          tax-efficiently as possible. We can advise regarding:
        </p>

        <ul>
          <li>Personal tax planning</li>
          <li>Extracting profit from your business</li>
          <li>Remuneration strategies</li>
          <li>Tax-efficient savings and investments</li>
          <li>Planning for a comfortable retirement</li>
          <li>Estate and inheritance tax planning</li>
          <li>Gifting strategies</li>
        </ul>

        <h2>Corporate Finance Services</h2>
        <span id="corporate-finance" className="services__offset-anchor">
          &nbsp;
        </span>

        <p>
          We can help you to secure the essential deals for your business. Areas
          where we can assist include:
        </p>

        <ul>
          <li>Securing loans</li>
          <li>Accessing government grants</li>
          <li>Mergers and acquisitions</li>
          <li>Exit planning</li>
          <li>Valuing your business</li>
          <li>Due diligence for investors</li>
        </ul>

        <h2>Business Support</h2>
        <span id="business-support" className="services__offset-anchor">
          &nbsp;
        </span>

        <p>
          Our support for you stretches far beyond the essential compliance
          services. We aim to be your business partners, working with you to
          ensure the best possible chance of success.
        </p>
        <p>
          <em>
            <strong>
              We want to help improve your bottom line, not just tell you what
              it is!
            </strong>
          </em>
        </p>
        <p>Areas where we can assist you include:</p>

        <ul>
          <li>Business start-up planning and advice</li>
          <li>Raising business finance</li>
          <li>Strategic planning</li>
          <li>Financial management</li>
          <li>Financial information systems</li>
          <li>Computer systems advice</li>
          <li>Cashflow forecasting and management</li>
          <li>Efficient billing and cash collection</li>
          <li>Long term plans for your business</li>
        </ul>

        <h2>Company Secretarial</h2>
        <span id="company-secretarial" className="services__offset-anchor">
          &nbsp;
        </span>

        <p>
          The Companies Act places strict obligations on companies to submit the
          required documents according to deadlines. We can provide peace of
          mind, by taking the hard work off your hands.
        </p>
        <p>Our Company Secretarial services include:</p>

        <ul>
          <li>Preparation and filing of statutory returns</li>
          <li>Preparation of minutes and resolutions</li>
          <li>Company formation</li>
          <li>Company searches</li>
        </ul>

        <h2>Auditing Services</h2>
        <span id="auditing-services" className="services__offset-anchor">
          &nbsp;
        </span>

        <p>
          We can perform your auditing and financial analysis with a minimum of
          fuss and maximum efficiency and accuracy.
        </p>
        <p>
          As well as giving you the peace of mind that your accounts are fully
          compliant with statutory requirements, we can identify strengths and
          weaknesses in the finances of your business, helping show the way to
          future success and profitability.
        </p>
        <p>Our core auditing services include:</p>

        <ul>
          <li>Statutory audits</li>
          <li>Internal audits</li>
          <li>Due diligence</li>
          <li>Minimising fraud exposure</li>
          <li>Specialist audits</li>
        </ul>

        <h2>Accounting Services</h2>
        <span id="accounting-services" className="services__offset-anchor">
          &nbsp;
        </span>

        <p>
          Let us provide you with more time to concentrate on running your
          business, and the peace of mind that your accounts and returns are in
          order.
        </p>

        <p>Our accounting and compliance services include:</p>

        <ul>
          <li>Preparation of annual accounts</li>
          <li>Preparation of periodic management accounts</li>
          <li>Book-keeping services</li>
          <li>Maintaining PAYE and VAT records and associated returns</li>
          <li>Preparation for Revenue investigations</li>
          <li>PAYE and PRSI compliance</li>
          <li>Self-assessment advice</li>
        </ul>

        <h2>Taxation</h2>
        <span id="taxation" className="services__offset-anchor">
          &nbsp;
        </span>

        <p>
          Our tax practice is structured to meet the needs of our clients. We
          provide a comprehensive range of services from the completion of tax
          returns under the self assessment system to complex consultancy
          assignments and strategic tax planning, including:
        </p>

        <ul>
          <li>corporate and general business tax advice</li>
          <li>Income Tax</li>
          <li>Capital Gains Tax</li>
          <li>Capital Acquisitions Tax</li>
          <li>Foreign Tax</li>
          <li>Double taxation relief</li>
          <li>Tax planning (link to succession planning)</li>
          <li>Stamp Duty</li>
        </ul>

        <p>
          We offer an integrated approach on all aspects of tax planning
          regarding your existing/future investments and appropriate structures
          to meet your ongoing needs.
        </p>

        <h2>Payroll Services</h2>
        <span id="payroll-services" className="services__offset-anchor">
          &nbsp;
        </span>

        <p>
          We believe the most valuable resource in any organisation is time. We
          provide a complete payroll service that eases the time pressures on
          management.
        </p>
        <p>
          Many organisations now use our payroll service. We calculate the net
          pay and PAYE/PRSI deductions. The numbers of employees in these firms
          can be as few as one and as many as 500. We can offer you:
        </p>

        <ul>
          <li>Preparation of Weekly/monthly payslips</li>
          <li>Professional and up to date advice on changes to PAYE/PRSI.</li>
          <li>Experienced personnel.</li>
          <li>Guaranteed confidentiality</li>
        </ul>

        <p>
          <strong>Key features of our service include:</strong>
        </p>

        <ul>
          <li>
            A payroll package tailored to suit individual companies (weekly,
            fortnightly, monthly, four-weekly etc.)
          </li>
          <li>No software costs for employers</li>
          <li>Provision of payslips</li>
          <li>Provision of reports designed to suit your needs</li>
          <li>Returns to the Revenue Commissioners filed on time</li>
          <li>Fixed fees agreed in advance</li>
        </ul>

        <p>
          Our payroll service can provide you with the peace of mind in knowing
          all payroll details are professionally dealth with.
        </p>

        <h2>Specialist services</h2>
        <span id="specialist-services" className="services__offset-anchor">
          &nbsp;
        </span>

        <p>
          We have a wealth of specialist knowledge and expertise in the
          following sectors:
        </p>

        <ul>
          <li>Pharmacies</li>
          <li>Motor Industry</li>
          <li>Doctors</li>
          <li>Solicitors</li>
          <li>Auctioneers</li>
          <li>Construction</li>
          <li>Credit Unions</li>
          <li>Food Processing</li>
          <li>Farming</li>
        </ul>
        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default Services;
