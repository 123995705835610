import React from "react";
import { NavLink } from "react-router-dom";
import { Query } from "react-contentful";
import { Helmet } from "react-helmet";
import ContactForm from "../Contact/ContactForm";
import "../Page/Page.scss";

const BlogIndex = () => (
  <Query
    contentType="blogPost"
    query={{
      include: 10,
    }}
  >
    {({ data, error, fetched, loading }) => {
      if (loading || !fetched) {
        return null;
      }

      if (error) {
        console.error(error);
        return null;
      }

      if (!data || !data.items[0]) {
        return (
          <div className="app__content-wrapper">
            <h1 className="alt-font">Something went wrong...</h1>
            <p>There are currently no published blog posts.</p>
          </div>
        );
      }

      // Process and pass in the loaded `data` necessary for your page or child components.
      return (
        <div className="app__content-wrapper">
          <Helmet>
            <title>Whately Accountants | Blog</title>
            <meta
              name="description"
              content="Latest blog posts from M.A. Whately Accountants"
            />
          </Helmet>
          <h1 className="alt-font">Latest Blog Posts</h1>
          {data.items.map((item, index) => {
            const createdDate = new Date(item.sys.createdAt);

            return (
              <div key={item.sys.id} className="page__blog-list-item">
                <h2 className="alt-font page__blog-list-heading">
                  <NavLink to={`/blog/${item.fields.slug}`}>
                    {item.fields.title}
                  </NavLink>
                </h2>
                <p className="page__blog-list-timestamp">
                  Published on {createdDate.toDateString()}
                </p>
                <p className="page__blog-list-intro">
                  {item.fields.introduction}
                </p>
              </div>
            );
          })}
          <div className="page__contact-wrapper">
            <h2 className="alt-font">Contact us now </h2>
            <ContactForm />
          </div>
        </div>
      );
    }}
  </Query>
);

export default BlogIndex;
