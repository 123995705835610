import React, { Component } from "react";
import { Helmet } from "react-helmet";
import ContactForm from "./ContactForm";
import "./Contact.scss";

class Contact extends Component {
  onSubmit = ({ fields, errors, isValid }) => {
    if (isValid) {
      document.getElementById("blark").submit(); // why blark? why not!
    }
  };

  inputChanged() {
    return;
  }

  render() {
    return (
      <div className="app__content-wrapper">
        <Helmet>
          <title>Whately Accountants | Contact Us</title>
        </Helmet>
        <h1 className="alt-font">Contact Us</h1>
        <ContactForm />
      </div>
    );
  }
}

export default Contact;
