import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "animate.css";
import "./Header.scss";

class Header extends Component {
  constructor() {
    super();
    this.state = {
      isMenuActive: false,
      menuTriggerClassesInactive:
        "hamburger hamburger--elastic header__menu-button",
      menuTriggerClassesActive:
        "hamburger hamburger--elastic header__menu-button is-active",
      menuClassesActive: "header__nav",
      menuClassesInactive: "header__nav header__nav--hidden",
      servicesMenuClass: "header__nav__with-dropdown",
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.showServicesMenu = this.showServicesMenu.bind(this);
    this.hideServicesMenu = this.hideServicesMenu.bind(this);
  }

  componentDidMount() {
    this.setState({
      menuTriggerClasses: this.state.menuTriggerClassesInactive,
      menuClasses: this.state.menuClassesInactive,
      isMenuActive: false,
    });
  }

  toggleMenu() {
    if (this.state.isMenuActive) {
      this.setState({
        menuTriggerClasses: this.state.menuTriggerClassesInactive,
        menuClasses: this.state.menuClassesInactive,
        isMenuActive: false,
      });
    } else {
      this.setState({
        menuTriggerClasses: this.state.menuTriggerClassesActive,
        menuClasses: this.state.menuClassesActive,
        isMenuActive: true,
      });
    }
  }

  showServicesMenu() {
    this.setState({
      servicesMenuClass:
        "header__nav__with-dropdown header__nav__with-dropdown--hover",
    });
  }

  hideServicesMenu() {
    this.toggleMenu();
    this.setState({
      servicesMenuClass: "header__nav__with-dropdown",
    });
  }

  render() {
    return (
      <header className="header">
        <div className="header__mobile-contactus">
          <a href="tel:+353416853162" className="header__super__item">
            <img
              className="header__super__icon-phone"
              src="../img/icon_phone.svg"
              alt="Phone icon"
            />
            <span className="header__super__text">
              <strong>Phone</strong>
              <span>+353 41 6853162</span>
            </span>
          </a>
          <a href="mailto:info@mawhately.ie" className="header__super__item">
            <img
              className="header__super__icon-email"
              src="../img/icon_email.svg"
              alt="Email icon"
            />
            <span className="header__super__text">
              <strong>Email</strong>
              <span>info@mawhately.ie</span>
            </span>
          </a>
        </div>
        <div className="header__super-container">
          <div className="app__container">
            <div className="header__super">
              <div className="header__super__item header__super__item--first">
                <img src="../img/logo_acca.png" alt="ACCA icon" />
              </div>
              <a href="tel:+353416853162" className="header__super__item">
                <img
                  className="header__super__icon-phone"
                  src="../img/icon_phone.svg"
                  alt="Logo for Chartered Accountants Ireland"
                />
                <span className="header__super__text">
                  <strong>Phone</strong>
                  <span>+353 41 6853162</span>
                </span>
              </a>
              <a
                href="mailto:info@mawhately.ie"
                className="header__super__item"
              >
                <img
                  className="header__super__icon-email"
                  src="../img/icon_email.svg"
                  alt="Email icon"
                />
                <span className="header__super__text">
                  <strong>Email</strong>
                  <span>info@mawhately.ie</span>
                </span>
              </a>
              <div className="header__super__item">
                <img
                  className="header__super__icon-clock"
                  src="../img/icon_clock.svg"
                  alt="Opening hours icon"
                />
                <span className="header__super__text">
                  <strong>Opening Hours</strong>
                  <span>9am - 6PM Monday to Friday</span>
                </span>
              </div>
              <a
                href="https://www.google.com/maps/place/M+A+Whately+%26+Company+Accountants/@53.854911,-6.5436947,17z/data=!3m1!4b1!4m5!3m4!1s0x48674a5af8604a8d:0xf80d03e9b1bbeacd!8m2!3d53.8549079!4d-6.541506"
                className="header__super__item  header__super__item--last"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="header__super__icon-address"
                  src="../img/icon_address.svg"
                  alt="Address icon"
                />
                <span className="header__super__text">
                  <strong>Address</strong>
                  <span>Jervis St, Ardee, Co. Louth</span>
                </span>
              </a>
            </div>
          </div>
        </div>

        <div className="header__logo-container">
          <div className="header__logo-container__inner">
            <div>
              <NavLink to={`/`} exact={true}>
                <img
                  className="header__logo animated fadeInDown delay-1s"
                  src="../img/logo_vector.svg"
                  alt="Company logo"
                />
              </NavLink>
              <div className="header__subtext  animated fadeInUp delay-1s">
                Chartered Certified Accountants & Registered Auditors
              </div>
            </div>

            <div>
              <div className="header__buttons-container">
                <NavLink
                  to={`/contact`}
                  className="header__buttons-container__button"
                  exact={true}
                >
                  Book a consultation
                </NavLink>
              </div>
            </div>

            <div>
              <button
                className={this.state.menuTriggerClasses}
                type="button"
                aria-label="Menu"
                aria-controls="navigation"
                onClick={this.toggleMenu}
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button>
            </div>
          </div>
        </div>

        <div className="header__nav__container">
          <div className="app__container">
            <nav className={this.state.menuClasses} id="navbar">
              <NavLink
                to={`/`}
                activeClassName="header__nav__link--selected"
                className="header__nav__link"
                exact={true}
                onClick={this.toggleMenu}
                onMouseOver={this.hideServicesMenu}
              >
                Home
              </NavLink>
              <NavLink
                to={`/about`}
                activeClassName="header__nav__link--selected"
                className="header__nav__link"
                exact={true}
                onClick={this.toggleMenu}
                onMouseOver={this.hideServicesMenu}
              >
                About Us
              </NavLink>
              <div className={this.state.servicesMenuClass}>
                <NavLink
                  to={`/services`}
                  activeClassName="header__nav__link--selected"
                  className="header__nav__link"
                  onClick={this.toggleMenu}
                  onMouseOver={this.showServicesMenu}
                >
                  Services
                </NavLink>
                <div
                  className="header__nav__submenu"
                  onMouseLeave={this.hideServicesMenu}
                >
                  <ul>
                    <li>
                      <NavLink
                        onClick={this.hideServicesMenu}
                        to={`/services/taxation`}
                      >
                        Tax
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={this.hideServicesMenu}
                        to={`/services/accounting`}
                      >
                        Accounting
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={this.hideServicesMenu}
                        to={`/services/audit`}
                      >
                        Audit
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={this.toggleMenu}
                        to={`/services/payroll`}
                      >
                        Payroll
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={this.hideServicesMenu}
                        to={`/services/secretarial`}
                      >
                        Secretarial
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={this.hideServicesMenu}
                        to={`/services/wealth-management`}
                      >
                        Wealth Management
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={this.hideServicesMenu}
                        to={`/services/corporate-finance`}
                      >
                        Corporate Finance
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={this.hideServicesMenu}
                        to={`/services/business-consultancy`}
                      >
                        Consultancy
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={this.hideServicesMenu}
                        to={`/services/specialist`}
                      >
                        Specialist services
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <NavLink
                to={`/blog`}
                activeClassName="header__nav__link--selected"
                className="header__nav__link"
                exact={true}
                onClick={this.toggleMenu}
                onMouseOver={this.hideServicesMenu}
              >
                Blog
              </NavLink>
              <NavLink
                to={`/resources`}
                activeClassName="header__nav__link--selected"
                className="header__nav__link"
                exact={true}
                onClick={this.toggleMenu}
                onMouseOver={this.hideServicesMenu}
              >
                Resources
              </NavLink>
              <NavLink
                to={`/links`}
                activeClassName="header__nav__link--selected"
                className="header__nav__link"
                exact={true}
                onClick={this.toggleMenu}
                onMouseOver={this.hideServicesMenu}
              >
                Links
              </NavLink>
              <NavLink
                to={`/impact`}
                activeClassName="header__nav__link--selected"
                className="header__nav__link"
                exact={true}
                onClick={this.toggleMenu}
                onMouseOver={this.hideServicesMenu}
              >
                Impact
              </NavLink>
              <NavLink
                to={`/contact`}
                activeClassName="header__nav__link--selected"
                className="header__nav__link"
                exact={true}
                onClick={this.toggleMenu}
                onMouseOver={this.hideServicesMenu}
              >
                Contact Us
              </NavLink>
            </nav>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
