import React, { Component } from "react";
import { Helmet } from "react-helmet";

class Resources extends Component {
  render() {
    return (
      <div className="app__content-wrapper">
        <Helmet>
          <title>Whately Accountants | Resources</title>
        </Helmet>
        <h1 className="alt-font">Resources</h1>
        <h2>Revenue</h2>
        <ul>
          <li>
            <a
              href="https://www.revenue.ie/en/vat/vat-rates/search-vat-rates/VAT-rates-database.aspx"
              target="_blank"
              rel="noopener noreferrer"
            >
              Revenue Vat Rate Database
            </a>
          </li>
          <li>
            <a
              href="https://www.revenue.ie/en/tax-professionals/ebrief/index.aspx"
              target="_blank"
              rel="noopener noreferrer"
            >
              Revenue E-Brief
            </a>
          </li>
        </ul>
        <h2>Excel files</h2>
        <ul>
          <li>
            <a
              href="http://www.accountingnet.ie/artman2/uploads/12monthcashflow1.xls"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cashflow statement -12 Months
            </a>
          </li>
          <li>
            <a
              href="http://www.accountingnet.ie/artman2/uploads/expenses.xls"
              target="_blank"
              rel="noopener noreferrer"
            >
              Motor Expenses
            </a>
          </li>
          <li>
            <a
              href="http://www.accountingnet.ie/artman2/uploads/profitloss1.xls"
              target="_blank"
              rel="noopener noreferrer"
            >
              Profit &amp; Loss
            </a>
          </li>
        </ul>
        <h2>Word files</h2>
        <ul>
          <li>
            <a
              href="http://www.accountingnet.ie/artman2/uploads/businessplanwizard.doc"
              target="_blank"
              rel="noopener noreferrer"
            >
              Business Plan
            </a>
          </li>
          <li>
            <a
              href="http://www.accountingnet.ie/artman2/uploads/employeeevaluation.doc"
              target="_blank"
              rel="noopener noreferrer"
            >
              Employee Evaluation
            </a>
          </li>
        </ul>
        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default Resources;
