import React, { Component } from "react";
import { Helmet } from "react-helmet";

class NotFound extends Component {
  render() {
    return (
      <div className="app__content-wrapper">
        <Helmet>
          <title>Whately Accountants | Not found</title>
        </Helmet>
        <h1 className="alt-font">Page Not Found</h1>
        <p>
          This page is broken. Unfortunately the page you requested could not be
          found on our servers.
        </p>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default NotFound;
