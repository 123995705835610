import React from "react";
import { Query } from "react-contentful";
import { NavLink } from "react-router-dom";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Helmet } from "react-helmet";
import ContactForm from "../Contact/ContactForm";
import "../Page/Page.scss";

const BlogPost = (props) => (
  <Query
    contentType="blogPost"
    query={{
      "fields.slug[in]": `${props.match.params.slug}`,
    }}
  >
    {({ data, error, fetched, loading }) => {
      if (loading || !fetched) {
        return null;
      }

      if (error) {
        console.error(error);
        return null;
      }

      if (!data || !data.items[0]) {
        return (
          <div className="app__content-wrapper">
            <h1 className="alt-font">Something went wrong...</h1>
            <p>This blog post cannot be found.</p>
          </div>
        );
      }

      const {
        content,
        metaDescription,
        metaTitle,
        title,
        introduction,
        author,
      } = data.items[0].fields;
      const rawRichTextField = content;
      const richTextField = documentToReactComponents(rawRichTextField);

      const createdDate = new Date(data.items[0].sys.createdAt);

      const leadImage = data.items[0].fields.leadImage;

      // Process and pass in the loaded `data` necessary for your page or child components.
      return (
        <div className="app__content-wrapper">
          <Helmet>
            <title>Whately Accountants | {metaTitle}</title>
            <meta name="description" content={metaDescription} />
          </Helmet>
          <div className="page__blog-post">
            <p>
              <NavLink to="/blog">
                <i className="page__blog-arrow-left"></i>All blog posts
              </NavLink>
            </p>
            <h1 className="alt-font page__blog-page-heading">{title}</h1>
            {leadImage && (
              <img
                className="page__blog-lead-image"
                src={leadImage.fields.file.url}
                alt="article promo"
              />
            )}
            <h2 className="alt-font page__blog-page-sub-heading">
              {introduction}
            </h2>
            {author && <h4> by {author}</h4>}
            <p className="page__blog-list-timestamp">
              <strong>Published on {createdDate.toDateString()}</strong>
            </p>
            {richTextField}
          </div>
          <div className="page__contact-wrapper">
            <h2 className="alt-font">Contact us now </h2>
            <ContactForm />
          </div>
        </div>
      );
    }}
  </Query>
);

export default BlogPost;
